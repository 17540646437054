﻿"use strict";


const cookiebanner = {
	init: function () {

		for (var button of document.querySelectorAll("[data-accept-cookies]")) {
			button.addEventListener("click", function (e) {

				// prevent default event
				e.preventDefault();

				// set cookie local
				document.cookie = "accept-cookies=1; " + new Date(new Date().getFullYear() + 100, 1, 1).toUTCString();

				// remove banner
				var banner = button.parentNode;
				button.parentNode.parentNode.removeChild(banner);
			});
		}

	}
}


module.exports = cookiebanner.init();